import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { numberWithCommas } from '../../../../../Utils/NumberToComma';
import {LineItemCostAllocation} from '../../Submit/MultiSubmitInvoice/CostAllocation/LineItemCostAllocation'
import { get, post } from '../../../../../Model/Network/Config/Axios';
import { handleError, showToast } from '../../../../Common/fToast/ToastCallback';
import { Modal } from '../../../../Common/fModal/Modal';
import ToggleSwitch from '../../../../Common/fSwitch';
import LineItemModal from './LineItemModal';
import AddLineItem from './AddLineItem';
import PastInvoices from './PastInvoices';
import {useHistory} from 'react-router-dom';
import EditAdditionalCharge from './EditAdditionalCharge';
import './fInvoice.css';
import _ from 'lodash';

function Invoice(props){

    const [open,setOpen] = useState(undefined);
    const [previousType,setPreviousType] = useState(undefined);
    const [showCustomFormId, setShowCustomFormId] = useState(false);
    const [showServiceCustomFormId, setShowServiceCustomFormId] = useState(false);

	const [updateGstCredit, setUpdateGstCredit] = useState(false)
	const [edit, setEdit] = useState(undefined)
	const [remove, setRemove] = useState(undefined)
	const [credit, setCredit] = useState(false)
	const [addItem, setAddItem] = useState(false)
	const [pastInvoices, setPastInvoices] = useState([])
    const [editAdditionalCharge,setEditAdditionalCharge] = useState();
    const [rcLineItemTax,setRcLineItemTax] = useState({isRCItem:false,tax:0});
    const getCredit = useRef()
    const HISTORY = useHistory();

    let {debitNote,invoiceStatus,systemInvoiceId,vendorCode,vendorAddress,panNumber,enableAddLineItem,enableLineItemPlugin,panStatus} = props.data;
    const {disableUpdate} = props;

    useEffect(()=>{
        get(`vendor/costAllocation/getAllocations?referenceType=vendor-invoice&referenceId=${systemInvoiceId}`,(e,r)=>{
            if(r) {
                setPreviousType({
                    key:r.data.baseType,
                    value:r.data.baseType
                })
            }
        })
    },[])

    useEffect(()=>{
        if(props.data && props.data.table && props.data.table.length){
            let RCItem = false;
            let calcTax = 0;
            props.data.table.forEach(el =>{
                if(el.rcItem){
                    RCItem = true;
                    calcTax += el.tax_amt;
                }
            })
            setRcLineItemTax({isRCItem:RCItem,tax:calcTax});
        }
    },[props])


    useEffect(() => {
        if (props.data.vendorDetails && props.data.vendorDetails.id) {
            let mData = {
                filter: `${props.data.vendorDetails.id}@t0.vendor_id`
            }
            get(`vendor/invoicing/invoices`, (e, r) => {
                if (r) {
                    setPastInvoices(r.invoices)
                }
            }, mData)
        }
    }, [props.data.vendorDetails])
    
const onSaveItem = (payload) =>{
    if (payload.tds) {
        const { tdsSlab, tdsCode, tdsDesc } = payload.tds;
        delete payload.tds;
        payload.tdsSlab = tdsSlab;
        payload.tdsCode = tdsCode;
        payload.tdsDesc = tdsDesc;
    }
   post(`vendor/invoicing/${systemInvoiceId}/invoiceItem.update`,
   {...payload},
   (e,r) =>{
    if(r) {
        props.onReload()
        showToast({
            type: 'success',
            message:'Line Item Updated Successfully'
        })
    } else if(e){
       handleError(e)
    }
 setEdit(false)
   })
}

const onAddLineItem = (payload) =>{
   post(`vendor/invoicing/${systemInvoiceId}/invoiceItem.add`,
   {data:payload},
   (e,r) =>{
    if(r) {
        props.onReload();
        post(`vendor/costAllocation/removeAllocation`,{invoiceId:systemInvoiceId},(e,r)=>{
            if(r) {
                props.onReload()
            } else {
                props.onReload()
            }
        })
        showToast({
            type: 'success',
            message:'Line Item Added Successfully'
        })
    } else if(e){
       handleError(e)
    }
    setAddItem(false)
   })
}

    const renderTable = (item, index)=>{
        return(
            <div key={`entry-${index}`} style={{display: 'flex', justifyContent: 'space-evenly', margin: '15px 0px'}}>
                <div style={{width: '160px'}}>
                    {item.desc}<br/>
                    {props.data.poNumber?`Item ref no :${item.referenceId}`:null} {props.data.poNumber?<br/>:null}
                    {item.orderId?`PO Number:${item.orderId}`:null} <br/>
                    Code: {item.hsn} <br /> 
                    Service : {item.service} <br></br>
                    {item.glCode ? <>GL Code : {item.glCode} <br/></> : null}
                    {item.serviceDesc && (
                     `Desc : ${item.serviceDesc}`
                    )}
                    {item.notes ?<>Notes : {item.notes} <br></br></> :null}
                    <div style={{}}>
                  <p> {item.gstNonCreditable ? `Gst  Credit  Applicable : No`:`Gst Credit  Applicable :Yes`}</p>
                    {item.budget_id && <p>Budget Id: {item.budget_id}</p>}
                    {item.budgeted_amount && <p>Budget Amount: {item.budgeted_amount}</p>}
                    {item.budget_status && <p>Budget Status: {item.budget_status}</p>}
                    {item.reverse_budget_id && <p>Reverse Budget Id: {item.reverse_budget_id}</p>}
                    {item.reverse_budgeted_amount && <p>Reverse Budget Amount: {item.reverse_budgeted_amount}</p>}
                    {item.reverse_budget_status && <p>Reverse Budget Status: {item.reverse_budget_status}</p>}
                    {invoiceStatus === 'APPROVAL' && 
                    <div className="penciledit" onClick={()=>{ 
                      if(disableUpdate) return;
                      setUpdateGstCredit(item)
                      setCredit(!item.gstNonCreditable)
                    }} 
                    style={{display:'inline-block',height:10,width:10}}
                    />
                    }
                    </div>
                    {item.tds && (
                        <div>
                             tdsAmount : {item.tds.tdsAmount} <br></br>
                             tdsCode : {item.tds.tdsCode} <br></br>
                             tdsRate : {item.tds.tdsRate} <br></br>
                             tds Desc : {(item.tds && item.tds.tdsDesc) || '-'} <br></br>
                        </div>
                    )}
                    {
                        item.attrs && Object.keys(item.attrs).length > 0 &&
                            item.attrs["lineItemReferenceNo"] ?
                            <p>Line Item Reference No : {item.attrs["lineItemReferenceNo"]}</p> : <></>
                    }
                    {
                        item.attrs && Object.keys(item.attrs).length > 0 &&
                            item.attrs["SO Number"] ?
                            <p>SO No : {item.attrs["SO Number"]}</p> : <></>
                    }
                    {item.forms && item.forms.length > 0 ? (
					<div>
				      <div 
					     style={{paddingTop:5,cursor: 'pointer'}}
					      onClick={() => setShowCustomFormId(showCustomFormId && showCustomFormId === item.id ? false : item.id)}>
						<h4>
						{showCustomFormId === item.id ? 'Hide': 'Show'}Custom form</h4>
				    	</div> <br />
					  {showCustomFormId === item.id &&
						item.forms.map((e) => (
						<div style={{ flexDirection: 'column' }} key={e.key}>
            	       	<p style={{fontWeight:250,color:'grey'}}> {e.key}: <span style={{fontWeight:500,color:'black'}}>{e.value}</span></p>
						 <br />
						</div>
						))}
					</div>
				    ) : null}
                    {item.serviceAttrs && _.size(item.serviceAttrs) > 0 ? (
                            <div>
                                <div className='mt2'
                                    style={{ fontSize: 13, fontWeight: '700', cursor: 'pointer' }}
                                    onClick={() => setShowServiceCustomFormId(showServiceCustomFormId && showServiceCustomFormId === item.id ? false : item.id)}>
                                    <p> {showServiceCustomFormId === item.id ? 'Hide' : "Show Service Tags"}</p>
                                </div>
                                <br />
                                {showServiceCustomFormId === item.id &&
                                    _.keys(item.serviceAttrs).map((e) => (
                                        <div style={{ flexDirection: 'column', marginTop: -10, textAlign: 'left' }} key={e}>
                                            <p style={{ fontWeight: 250, color: 'grey' }}> {e}: <span style={{ fontWeight: 500, color: 'black' }}>{item.serviceAttrs[e]}</span></p>
                                            <br />
                                        </div>
                                    ))}
                            </div>
                        ) : null}
                    </div>
               
                {
                    props.data.costAllocationDone || (props.data.enableCostAllocation && props.data.status==='APPROVAL') ?
                    <div style={{width:100,display:'flex'}}>
                         <div style={{
                             backgroundColor: "var(--green-color)",
                             color: "#fff",
                             height: 20,
                             width: 20,
                             borderRadius: "50%",
                             fontWeight: "bold",
                             cursor: "pointer",
                             fontSize: 16,
                             display: "flex",
                             alignItems: "center",
                             justifyContent: "center",
                             marginRight:5
                             }} onClick={()=>{
                                setOpen(index+1)
                            }}>
                             +
                         </div>
                        
                        {
                            item.isAllocationAvailable ?
                            <div onClick={()=>{
                                setOpen(index+1)
                            }} style={{cursor:'pointer',color:'var(--primary-color)',marginLeft:5}}>View</div>:<div>-</div>
                        }
                    </div>
                    :null

                }
                <div style={{width: '100px',}}> {item.price ?numberWithCommas(item.price.toFixed(2)):item.price}</div>
                <div style={{width: '100px',}}> {item.qtty}</div>
                {debitNote?<div style={{width: '100px',}}> {item.debitQty}</div>:null}
                <div style={{width: '100px',}}> {item.base_amt ?numberWithCommas(item.base_amt.toFixed(2)):item.base_amt}</div>
                {!props.data.singleLine && !props.data.poNumber && <div style={{width: '100px',}}> {item.taxSlab}%</div>}
                <div style={{width: '100px'}}> {item.tax_amt ?numberWithCommas(item.tax_amt.toFixed(2)):numberWithCommas(item.tax_amt)}</div>
                <div style={{width: '100px', textAlign: 'right'}}> {item.amount ?numberWithCommas(item.amount.toFixed(2)):numberWithCommas(item.amount)} </div>
                {props.budgetingV2 && <div style={{ width: '100px', textAlign: 'right',display:'flex',justifyContent:'center' }}>
                    <div style={{
                        width: 45
                    }}>
                        <div style={{ width: 44 }}>
                            <div onClick={() => {
                                if(item.orderId || disableUpdate) return;
                                post(`vendor/invoicing/${systemInvoiceId}/invoicing.change`,{itemId:item.id,key:"autoBudgeting",value:(item.autoBudgeting === "yes") ? 'no' : 'yes'},(e,r)=>{
                                    if(r){
                                        showToast({
                                            type: 'success',
                                            message:'Line Item updated Successfully'
                                        })
                                        props.onReload();
                                    }else{
                                        handleError(e);
                                    }
                                })
                            }} className="portlet-switch">
                                <div className={`check ${(item.autoBudgeting === "yes") ? 'on' : 'off'}`} />
                            </div>
                        </div>
                    </div>
                </div>}
                <div style={{width: '100px', textAlign: 'right'}}>
               { invoiceStatus ==='APPROVAL' &&
                <span  style={{padding:5,color:'var(--primary-color)',cursor:'pointer'}}
                   onClick={() => setEdit(item)}>
                    EDIT
                </span>}
               {(invoiceStatus ==='APPROVAL' && enableLineItemPlugin && !disableUpdate)&&
                <span  style={{padding:5,color:'var(--red-color)',cursor:'pointer',}}
                   onClick={() => setRemove(item)}>
                    Remove
                </span>}
                </div>
            </div>
        )
    }

    let partial = 0;
    props.data.allSettlement.forEach(val=>{
        if(val.transferred) {
            partial = partial+val.amount
        }
    })

    let paidAmount=0
    props.data.allSettlement.forEach(val=>{
        if(val.transferred) {
            paidAmount = paidAmount+val.amount
        }
    })

    let totalPayable = +props.data.payable_amt||0;
    let bill_to_gstin = props.data.bill_to_gstin || undefined;
    let totalFinalPayable = 0;
    if(props.data.mSettlement.amount) {
         totalFinalPayable = (props.data.mSettlement.paidAmount-(props.data.mSettlement.partial+props.data.mSettlement.partialTds)).toFixed(2)
    }
     // additional charges included in total amount
    let totalAdditionalCharge = Number(props.data.totalAdditionalCharge) || 0;
    let baseAdditionalCharge = Number(props.data.baseAdditionalCharge) || 0;
    let grossAdditionalCharge = Number(props.data.grossAdditionalCharge) || 0;
    let additionalChargesTds = Number(props.data.additionalChargesTds) || 0;
    let totalChargesCombine = totalAdditionalCharge+ baseAdditionalCharge+grossAdditionalCharge-additionalChargesTds;

    if(props.data.isCreditNote || props.data.isDebitNote){
        let credit = +props.data.creditNoteTotal||0 
        let debit = +props.data.debitNoteTotal ||0

        totalPayable =  totalPayable  - credit + debit;
    } 

    if(props.data.creditNoteTds) {
        totalPayable += props.data.creditNoteTds;
        totalFinalPayable = Number(totalFinalPayable)+props.data.creditNoteTds
    }

    if(props.data && props.data.status ==='APPROVAL' && !props.data.mSettlement.amount) {
        totalFinalPayable  =  totalPayable - paidAmount
    }
    
 return(
        <div className='FadeRight-Effect' style={{width: '100%', fontSize: '12px'}}>
        <div style={{textAlign: 'center', fontSize: '22px', fontWeight: 'bold'}}>INVOICE</div>
            {
              
                props.data.discount?
                    <p style={{
                        top:10,
                        left:0,
                        padding:'12px 30px',
                        position:'absolute',
                        background:"green",
                        color:'var(--white-color)',
                        fontSize:11
                    }}>
                        Discounted at {numberWithCommas(props.data.discount)} %
                    </p>:undefined
            }
            {
              
              props.data.rcVendor ||  props.data.rcInvoice || rcLineItemTax.isRCItem ||( props.data.vendorDetails.msmeId && props.data.vendorDetails.msmeId!=='NA') || props.data.vendorDetails.eInvoiceApplicable ?
                  <p style={{
                      top:85,
                      left:0,
                      padding:'12px 30px',
                      position:'absolute',
                      background:'var(--primary-color)',
                      color:'var(--white-color)',
                      fontSize:11
                  }}>
                    {
                       (props.data.vendorDetails && props.data.vendorDetails.msmeId && props.data.vendorDetails.msmeId  !== 'NA') ?'MSME VENDOR INVOICE' :null
                    }
                    {
                        (props.data.rcVendor ||  props.data.rcInvoice || rcLineItemTax.isRCItem) ?'RCM INVOICE' :null
                    }
                    {
                        props.data.vendorDetails.eInvoiceApplicable ? 'E-Invoice' : null
                    }
                  </p>:undefined
          }
        <div style={{position: 'relative', border: '1px solid var(--border-color)', padding: '20px', borderRadius: '15px', marginTop: '30px'}}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                    <b>Vendor</b><br/>
                    {props.data.vendor}<br/>
                    Vendor Code: {vendorCode} <br></br>
                    Email: {props.data.email}<br/>
                    GSTIN: {props.data.vendor_id || 'NA'} <br />
                    PAN: {panNumber || 'NA'} <br />
                    panStatus: {panStatus || 'NA'} <br />
                    {props.data.vendorDetails?props.data.vendorDetails.gstinStatus?<>GSTIN STATUS : {props.data.vendorDetails.gstinStatus} <br /></>:null:null}
                    {props.data.vendorDetails?props.data.vendorDetails.taxPayerType?<>Tax Payer Type : {props.data.vendorDetails.taxPayerType} <br /></>:null:null}
                      MSME / Udyam  Number : {props.data.vendorDetails ?props.data.vendorDetails.msmeId || 'NA':'NA'} <br />
                     Address: {vendorAddress && vendorAddress.address} <br></br>
                    { vendorAddress && vendorAddress.state} {vendorAddress && vendorAddress.pincode} <br></br>
                
                </div>
                <div style={{textAlign: 'right'}}>
                 Date: {props.data.date}<br/>
                {props.data.due?<>Due Date: {moment(props.data.due).format('DD-MM-YYYY')} <br/></>:<></>}
                Invoice Number: {props.data.Invoice_id}<br/>
                     {props.data.poNumber ? <p style={{ cursor: 'pointer' }} onClick={() => HISTORY.push(`/app/vendor/procurement/order/${props.data.poId}/details`)}>PO Number: {props.data.poNumber}</p> : ''}<br />
                     {props.data && props.data.multiPo && props.data.multiPo.length > 0 ? <>
                         PO Number
                         {props.data.multiPo.map((e) => <p style={{ cursor: 'pointer' }} onClick={() => HISTORY.push(`/app/vendor/procurement/order/${e.id}/details`)}>{e.poId}</p>)}<br />
                     </>
                    : null}
                {props.data.asn ? `ASN: ${props.data.asn.asnId}`: ''}<br/>
                {props.data.asn ? `ASN Created On: ${moment(props.data.asn.createdAt).format('DD-MM-YYYY')}`: ''}<br/>
                {props.data.grnNos && props.data.grnNos.length ? `GRN: ${props.data.grnNos.join(",\n")}`: ''}<br/>
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '20px'}}>
                <div style={{paddingRight: '20px'}}>
                    <b>Billing Address</b><br/>
                    {props.data.bill_to.title?<span>{props.data.bill_to.title}<br></br></span>:null}
                    {props.data.bill_to.registeredName?<span>{props.data.bill_to.registeredName}<br></br></span>:null}
                    {props.data.bill_add}<br/>
                    GSTIN: {props.data.bill_to.gstin || 'NA'} <br />

                </div>
                <div style={{textAlign: 'right', paddingLeft: '20px'}}>
                    <b>Shipping Address</b><br/>
                    {props.data.ship_to.title?<span>{props.data.ship_to.title}<br></br></span>:null}
                    {props.data.ship_to.registeredName?<span>{props.data.ship_to.registeredName}<br></br></span>:null}
                    {props.data.ship_add}<br></br>
                    {props.data.ship_to.gstin?<span>{props.data.ship_to.gstin}<br></br></span>:null}


                </div>
            </div>
        </div>

        <div style={{display: 'flex', justifyContent: 'space-evenly', marginTop: '30px', fontWeight: 'bold', paddingBottom: '10px', borderBottom: '1px solid var(--border-color)'}}>
            <div style={{width: '160px'}}>Desciption</div>
            {
                props.data.enableCostAllocation && props.data.status==='APPROVAL' ?
                <div style={{width:100}}>
                    Allocation
                </div>
                :null
            }
            {
                props.data.costAllocationDone ?
                <div style={{width:'100px'}}>
                    Allocation
                </div>
                :null
            }
            <div style={{width: '100px',}}>Price/Unit</div>
            <div style={{width: '100px',}}>Qty</div>
            {debitNote?<div style={{width: '100px',}}>Debit Qty</div>:null}
            <div style={{width: '100px',}}>Base Amt</div>
            {!props.data.singleLine && !props.data.poNumber && <div style={{width: '100px',}}>Tax%</div>}
            <div style={{width: '100px'}}>Tax Amt</div>
            <div style={{width: '100px', textAlign: 'right'}}>Amount (INR)</div>
            {props.budgetingV2 && <div style={{width: '100px', textAlign: 'right'}}>Auto Budgeting</div>}
            <div style={{width: '100px', textAlign: 'right'}}>Actions</div>
            
        </div>
         {
         props.data.table.map(renderTable)
         }
      {enableAddLineItem  ?  
       <p
              style={{
                marginTop: 12,
                color: "var(--primary-color)",
                fontSize: 13,
                cursor: "pointer",
                fontWeight: 600,
                marginLeft:1
              }}
              onClick={() => {
                setAddItem(true);
              }}
            >
              + Add Line Item
          </p>
          : null
          }
         {props.additionalCharges && props.additionalCharges.length > 0 &&
             <div>
                <div  style={{marginLeft:1,fontWeight:'bold',marginTop:20,}}>Additional Charges</div>
                 {
                     props.additionalCharges.map(((item, index) => {
                         return (
                             <div key={index.toString()} style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '30px', paddingBottom: '10px', borderBottom: '1px solid var(--border-color)' }}>
                                 <div style={{ width: '160px' }}>
                                   <strong>{item.type||'-'}</strong> <br/>
                                     Title : {item.title || '-'} <br />
                                     Description: {item.description}<br />
                                     taxSlab : {item.taxSlab||"-"}<br/>
                                     TDS Slab : {item.tdsRate||"-"}<br/>
                                 </div>
                                 <div style={{ width: '100px', }}>{'-'}</div>
                                 <div style={{ width: '100px', }}>{'-'}</div>
                                 <div style={{ width: '100px', }}>{item.base ? item.base.toFixed(2): '0'}</div>
                                 <div style={{ width: '100px', }}>{item.taxAmount ? item.taxAmount.toFixed(2):"-"}</div>
                                 <div style={{ width: '100px', textAlign: 'right' }}>{item.amount? item.amount.toFixed(2):"0"}</div>
                                 <div style={{ width: '100px', textAlign: 'right' }}>
                                     {invoiceStatus === 'APPROVAL' &&
                                         <span style={{ padding: 5, color: 'var(--primary-color)', cursor: 'pointer' }}
                                             onClick={() => setEditAdditionalCharge(item)}>
                                             EDIT
                                         </span>}
                                 </div>
                             </div>
                         )
                     }))

                 }
             </div>
         }
        <div className='invoice-taxes'>
            <div className='wrap'>
                <div>Base Amt: </div>
                <div> {numberWithCommas(props.data.total_base.toFixed(2))}</div>
            </div>
        </div>
        <div className='invoice-taxes'>
            <div className='wrap'>
                <div>SGST / UTGST: </div>
                <div> {numberWithCommas(props.data.sgst.toFixed(2))}</div>
            </div>
        </div>
        <div className='invoice-taxes'>
            <div className='wrap'>
                <div>CGST: </div>
                <div> {numberWithCommas(props.data.cgst.toFixed(2))}</div>
            </div>
        </div>
        <div className='invoice-taxes'>
            <div className='wrap'>
                <div>IGST: </div>
                <div> {props.data.igst ?numberWithCommas(props.data.igst.toFixed(2)):numberWithCommas(props.data.igst)}</div>
            </div>
        </div>

       
        <div className='invoice-taxes'>
            <div className='wrap'>
                <div>Tax Amt: </div>
                <div> {numberWithCommas(props.data.total_tax.toFixed(2))}</div>
            </div>
        </div>
        <div className='invoice-taxes'>
            <div className='wrap'>
                <div>Cess Amount: </div>
                <div> {props.data.cessAmount?numberWithCommas(props.data.cessAmount):0}</div>
            </div>
        </div>
        <div className='invoice-taxes'>
            <div className='wrap'>
                <div>Discount Amount: </div>
                <div> {props.data.discountAmount?numberWithCommas(props.data.discountAmount.toFixed(2)): numberWithCommas(props.data.discountAmount)}</div>
            </div>
        </div>
        <div className='invoice-taxes'>
            <div className='wrap'>
                <div>Round Off: </div>
                <div> {props.data.roundOff?props.data.roundOff: props.data.roundOff}</div>
            </div>
        </div>
        <div className='invoice-taxes'>
            <div className='wrap' style={{fontWeight: 'bold'}}>
                <div>Total Amt: </div>
                <div> {numberWithCommas(props.data.total_amt.toFixed(2))}</div>
            </div>
        </div>
        <div className='invoice-taxes'>
            <div className='wrap'>
                <div>Total TDS: </div>
                <div> {props.data.tds?numberWithCommas(props.data.tds.toFixed(2)): numberWithCommas(props.data.tds)}</div>
            </div>
        </div>
        {
            props.data.creditNoteTds ?
            <>
            <div className='invoice-taxes'>
                <div className='wrap'>
                    <div>Credit Note TDS: </div>
                    <div> {props.data.creditNoteTds?numberWithCommas(props.data.creditNoteTds.toFixed(2)): numberWithCommas(props.data.creditNoteTds)}</div>
                </div>
            </div>
            <div className='invoice-taxes'>
                <div className='wrap'>
                    <div> TDS Applicable: </div>
                    <div> {props.data.creditNoteTds?numberWithCommas(props.data.tds-props.data.creditNoteTds): numberWithCommas(props.data.tds-props.data.creditNoteTds)}</div>
                </div>
            </div>
            </>
            :null
        }
        <div className='invoice-taxes'>
            <div className='wrap'>
                <div>TCS: </div>
                <div> {props.data.tcs?numberWithCommas(props.data.tcs.toFixed(2)): 0}</div>
            </div>
        </div>
        {props.additionalCharges && props.additionalCharges.length > 0 && 
        <div className='invoice-taxes'>
            <div className='wrap'>
                <div>Total additional charge: </div>
                <div>{totalChargesCombine} </div>
            </div>
        </div>
        }
        {props.data.isCreditNote ?
            <>
            <div className='invoice-taxes'>
                <div className='wrap'>
                    <div>CreditNote :</div>
                    <div> {props.data.creditNoteTotal?numberWithCommas(props.data.creditNoteTotal.toFixed(2)): numberWithCommas(props.data.creditNoteTotal)}</div>
                </div>
            </div>
        </>:null}
        {props.data.isDebitNote ?
            <>
            <div className='invoice-taxes'>
                <div className='wrap'>
                    <div>DebitNote :</div>
                    <div> {props.data.isDebitNote?numberWithCommas(props.data.debitNoteTotal.toFixed(2)): numberWithCommas(props.data.creditNoteTotal)}</div>
                </div>
            </div>
        </>:null}

        <div style={{display: 'flex', padding: '10px 0px', fontWeight: 'bold', borderTop: '1px solid var(--border-color)', borderBottom: '1px solid var(--border-color)'}}>
            <div style={{flexGrow: 1}}>Total Vendor Payable Amount: </div>
            <div style={{width: '100px', textAlign: 'right'}}>{numberWithCommas(totalPayable+totalChargesCombine)}</div>
        </div>
        {
             props.data.rcInvoice && ! props.data.mSettlement.amount ?
             <>
                 <div className='invoice-taxes'>
                     <div className='wrap'>
                        <div>Reverse. Charges: </div>
                        <div> {numberWithCommas(props.data.total_tax.toFixed(2))}</div>
                    </div>
                </div>
                <div className='invoice-taxes'>
                     <div className='wrap'>
                        <div>Total Payable Amount : </div>
                        <div> {numberWithCommas(totalPayable.toFixed(2)- props.data.total_tax.toFixed(2) + totalChargesCombine)}</div>
                    </div>
                </div>
                
             </>
             : (rcLineItemTax.isRCItem && !props.data.mSettlement.amount) ?
             <>
                 <div className='invoice-taxes'>
                     <div className='wrap'>
                        <div>Reverse. Charges: </div>
                        <div> {numberWithCommas(rcLineItemTax.tax.toFixed(2))}</div>
                    </div>
                </div>
                <div className='invoice-taxes'>
                     <div className='wrap'>
                        <div>Total Payable Amount : </div>
                        <div> {numberWithCommas(props.data.payable_amt.toFixed(2)- rcLineItemTax.tax.toFixed(2)+ totalChargesCombine)}</div>
                    </div>
                </div>
                
             </>:null
        }
        {
            props.data.mSettlement.amount ?
            <>
                
                <div className='invoice-taxes'>
                     <div className='wrap'>
                        <div>Reverse. Charges: </div>
                        <div> {numberWithCommas(props.data.mSettlement.reverseCharge.toFixed(2))}</div>
                    </div>
                </div>
                <div className='invoice-taxes'>
                     <div className='wrap'>
                        <div>Partial Payment: </div>
                        <div> {numberWithCommas(props.data.mSettlement.partial.toFixed(2))}</div>
                    </div>
                </div>
                <div className='invoice-taxes'>
                     <div className='wrap'>
                        <div>Partial Advance Tds: </div>
                        <div> {numberWithCommas(props.data.mSettlement.partialTds.toFixed(2))}</div>
                    </div>
                </div>
                

                <div style={{display: 'flex', padding: '10px 0px', fontWeight: 'bold', borderTop: '1px solid var(--border-color)', borderBottom: '1px solid var(--border-color)'}}>
                    <div style={{flexGrow: 1}}>Total Payable Amount: </div>
                    <div style={{width: '100px', textAlign: 'right'}}> {numberWithCommas(totalFinalPayable+totalChargesCombine)}</div>
                </div>  
               
            </>
            :null
        }
        {!props.data.mSettlement.amount && (props.data.status==='APPROVAL' && paidAmount) && (
            <>
            <div className='invoice-taxes'>
                     <div className='wrap'>
                        <div>Paid Payment: </div>
                        <div> {numberWithCommas(paidAmount.toFixed(2))}</div>
                    </div>
                </div>
                <div style={{display: 'flex', padding: '10px 0px', fontWeight: 'bold', borderTop: '1px solid var(--border-color)', borderBottom: '1px solid var(--border-color)'}}>
                    <div style={{flexGrow: 1}}>Total Payable Amount: </div>
                    <div style={{width: '100px', textAlign: 'right'}}> {numberWithCommas(totalFinalPayable+totalChargesCombine)}</div>
                </div>  
            </>
        )}
        <div>
            <p style={{fontWeight:500,fontSize:16,marginTop:15,marginBottom:10}} >Past Invoices</p>
            <div style={{marginBottom: 0}}>
					<PastInvoices invoices={pastInvoices}/>
				</div>
        </div>
        <div style={{height: '40px'}}/>


        {
		open && previousType ?
		<LineItemCostAllocation
            disabled={disableUpdate}
			lineItems={[...props.data.table]}
			index={open}
            gstin={bill_to_gstin}
			view={invoiceStatus==='APPROVAL'?false:true}
            edit={invoiceStatus==='APPROVAL'}
            previousType={previousType}
            invoiceTotalAmount={props.data.total_amt}
			close={()=>{
				setOpen(false);
			}}

            init={(data)=>{

                let lineItemObject = {};
                props.data.table.filter((_,index)=>(index!==open-1)).forEach(i=>{
                    lineItemObject[i.id] = i.costAllocations
                })

                data.costAllocation.costAllocationAtItems = {
                    ...data.costAllocation.costAllocationAtItems,
                    ...lineItemObject
                }

                post(`vendor/invoicing/${systemInvoiceId}/invoicing.costAllocation.update`,data,(e,r)=>{
                    if(r) {
                        showToast({
                            type:'success',
                            message:'Cost Allocation Update Successfully'
                        })
                        setOpen(false);
                        props.init();
                    } else {
                        showToast({
                            type:'error',
                            message:e.data.message
                        })
                    }
                })
            }}
		/>
		:null
	  }
    {
		open && !previousType ?
		<LineItemCostAllocation
            disabled={disableUpdate}
			lineItems={[...props.data.table]}
			index={open}
			view={false}
            edit={false}
            gstin={bill_to_gstin}
            previousType={previousType}
            invoiceTotalAmount={props.data.total_amt}
			close={()=>{
				setOpen(false);
			}}
            updateLineItems={(items, type) => {
                setPreviousType(type);
                props.updateAllocation(items,type,open-1);
              }}

		/>
		:null
	  }
          {
		  	updateGstCredit ?
						<Modal 
							buttonName="Apply"
							onSave={()=>{
                                post(`/vendor/invoicing/${systemInvoiceId}/details.change`, {
                                    'key':'gst_creditable', 'value':`${credit}`,itemId:updateGstCredit.id}, (e, r) => {
                                    if (r) {
                                        props.onReload()
                                        showToast({
                                            type: 'success',
                                            message: 'Invoice Updated'
                                        })
                                    } else if (e) {
                                        handleError(e);
                                    }
                                    setUpdateGstCredit(null)
                                });

							}} 
							title={"Update GST Credit"}
							des="Please update Gst credit value" 
							close={() => {
								setUpdateGstCredit(null)
							}} 
							style={{
								save: {
									width: '100%',
									textAlign: 'center',
									fontSize: 17,
									background: "var(--red-color)",
									border: "2px solid var(--red-color)",
									padding: "12px 20px"
								},
							}} 
							show={true}
						>
							<ToggleSwitch
								label={"GST Credit Applicable"} 
								status={credit} 
								callback={(e) => {
                                  setCredit(!credit)
								 getCredit.current = e;
								}}/>
						</Modal> : null
				}  
        {edit && (
           <LineItemModal
            edit={edit}
            categoryId={props.data.categoryId}
            onSave={onSaveItem}
            tdsAtLineItem={props.tdsAtLineItem}
            tdsAtLineItemInContract={props.tdsAtLineItemInContract}
            vendorId={props.data.vendorId}
            close={() => setEdit(undefined)}
            category={props.data.category}
            contractId={props.data.contractId}
            disableUpdate={disableUpdate}
            />
          )}
        {addItem && (
           <AddLineItem
            categoryId={props.data.categoryId}
            tdsAtLineItem={props.tdsAtLineItem}
            tdsAtLineItemInContract={props.tdsAtLineItemInContract}
            vendorId={props.data.vendorDetails ? props.data.vendorDetails.id : undefined}
            onSubmit={onAddLineItem}
            bill_to={props.data.bill_to.title||null}
            onClose={() => setAddItem(undefined)} singleLine={props.data.singleLine}
            />
          )}
          {remove ? 
             	<Modal 
						buttonName="Remove"
						onSave={()=>{
                                post(`/vendor/invoicing/${systemInvoiceId}/invoiceItem.remove`, {
                                    "item_id":remove.id
                                }, (e, r) => {
                                    if (r) {
                                        post(`vendor/costAllocation/removeAllocation`,{invoiceId:systemInvoiceId},(e,r)=>{
                                            if(r) {
                                                props.onReload()
                                            } else {
                                                props.onReload()
                                            }
                                        })
                                        showToast({
                                            type: 'success',
                                            message: 'Item Removed successfully'
                                        })
                                    } else if (e) {
                                        handleError(e);
                                    }
                                    setRemove(null)
                                });

							}} 
							title={"Remove Line Item"}
							des="" 
							close={() => {
								setRemove(null)
							}} 
							style={{
								save: {
									width: '100%',
									textAlign: 'center',
									fontSize: 17,
									background: "var(--red-color)",
									border: "2px solid var(--red-color)",
									padding: "12px 20px"
								},
							}} 
							show={remove}
						>
							<div>Are you sure, you want to remove line item?</div>
						</Modal> 
                    : null}
            {
                editAdditionalCharge && <EditAdditionalCharge invoiceId={systemInvoiceId} id={editAdditionalCharge.id} onClose={()=>setEditAdditionalCharge()} vendorId={props.data.vendorId} onRealod={props.onReload}/>
            }
    </div>
 )
}



export default Invoice;
