import React, {useEffect, useRef, useState} from 'react';
import _  from 'lodash';
import {get, post} from '../../../../../../Model/Network/Config/Axios';
import {HalfModal} from '../../../../../Common/fModal/HalfModal';
import {handleError, showToast} from '../../../../../Common/fToast/ToastCallback';
import {Modal} from '../../../../../Common/fModal/Modal';
import {Title as Header} from "../../../../../Header/TopHeader";
import { ReadOnly } from '../../../../../Common/fCard/ButtonOutline/ReadOnly';
import { FunctionalInput } from '../../../../../Common/fInputs';
import TextEditorPrefill from '../../../../../Common/TextEditor/TextEditorPrefill';


const Index = (props) => {


    const [conditions, setConditions] = useState([]);
    const [addCondition, setAddCondition] = useState(false);
    const [selected, setSelected] = useState(props.conditions.length > 0 ? props.conditions.map(val => val.id) : []);
    const [edit, setEdit] = useState(false);
    let editorRef = useRef()
    let [content,setContent] = useState("");
    const TITLE = useRef({})
    let [modal,setModal] = useState(false)

    let getId = () => {
        return +props.id;
    }

    useEffect(() => {
        init();
    }, [])


    let init = () => {
        get(`vendor/po/requests/${getId()}/conditions`, (e, r) => {
            if (r) {
                setConditions(r.conditions);
            }
        })
    }

    let edits = () => {
        let content = "";
        if(editorRef && editorRef.current && editorRef.current.value) {
            content = editorRef.current.value;
        }
        post(`vendor/po/requests/${getId()}/updateDetails`,{
            key:'condition',
            value:content,
            keyId:edit.id
        },(e,r)=>{
            if(r) {
                props.init();
                showToast({
                    type: 'success',
                    message: "Condition Update Successfully"
                })
                setEdit(false);
            } else {
                showToast({
                    type:'error',
                    message:e.data.message
                })
            }
        })
       
    }

    let getStatus = () => {
        return props.data.status;
    }


    let mapCondition = (item, index) => {
        let active = _.includes(selected, item.id);
        return (
            <div key={index} style={{
                minHeight: 80,
                borderRadius: 2,
                marginBottom: 20,
                padding: 20,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                border: '1px dashed var(--border-color)',
                justifyContent: 'space-between'
            }}>
                <div style={{display: 'flex', alignItems: 'center'}}
                     onClick={() => {
                         let select = selected;

                         let active = _.includes(select, item.id);

                         if (active) {
                             select = select.filter(val => val !== item.id);
                             removeCondition(item);
                         } else {
                             select.push(item.id)
                             addConditions(item);
                         }
                         setSelected(select);

                     }}>
                    <div style={{
                       minHeight: 20,
                       minWidth: 20,
                       border: '1px solid #000',
                       borderRadius:4,
                       ...active ?
                            {
                                backgroundColor: 'var(--primary-color)',
                            } : {
                                backgroundColor: "var(--white-color)"
                            }
                    }}>

                    </div>
                    <div style={{marginLeft: 30}} dangerouslySetInnerHTML={{__html: `${item.content}`}}/>
                </div>

            </div>
        )
    }


    let removeCondition = (item) => {
        post(`vendor/po/requests/${getId()}/${item.id}/condition.remove`, {}, (e, r) => {
            if (r) {
                init();
                props.init();
                showToast({
                    type: 'success',
                    message: 'Condition removed succesfully'
                })

            }
        })

    }


    let addConditions = (item) => {
        post(`vendor/po/requests/${getId()}/${item.id}/conditions`, {}, (e, r) => {
            if (r) {
                init();
                props.init();
                showToast({
                    type: 'success',
                    message: 'Condition added succesfully'
                })

            }
        })
    }

    const addCustomCondition = () => {
        let title = TITLE.current.value;
        let content = ""
        if(editorRef && editorRef.current && editorRef.current.value) {
            content = editorRef.current.value;
        }
        if(!title) {
            showToast({
                type: 'error',
                message: 'Please enter title'
            })
            return
        }
        if (!content) {
            showToast({
                type: 'error',
                message: 'Please enter the content'
            })
            return
        }
        let payload = {
            custom: true,
            content:content ,
            title:title ,
        }
        post(`vendor/po/requests/${getId()}/conditions`,payload, (e, r) => {
            if (r) {
                init();
                props.init();
                showToast({
                    type: 'success',
                    message: 'Condition added succesfully'
                })
                setModal(false)

            }
            else if(e){
                handleError(e)
                return;
            }
        })
    }


    let showCondition = (item, index) => {
        return (
            <div key={index} style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 5,
            }}>
                <div style={{
                    height: 10,
                    minWidth: 10,
                    backgroundColor: "var(--text-color)",
                    marginRight: 10,
                    borderRadius: '50%',

                }}/>

                <div dangerouslySetInnerHTML={{__html: `${item.content}`}} style={{
                    color: 'var(--text-color)',
                    fontWeight: 500
                }}/>
                {
                    getStatus() === 'DRAFT' || getStatus()==='ADMINTOUCH' ?
                        <div onClick={(e) => {
                            e.preventDefault();
                            const html = item.content;
                            setContent(html);
                            setEdit(item);

                        }} className="penciledit"
                             style={{
                                 marginLeft: '10px',
                                 minHeight: 18,
                                 minWidth: 18,
                                 cursor: 'pointer',
                                 position: 'relative',
                                 zIndex: 3
                             }}/>
                        : null
                }
            </div>
        )
    }

    return (
        <div>
           <div style={{padding:'0px 20px'}}>
               <Header container={true} id="terms" title="Terms & Conditions"
                       des="All terms & conditions are visible below.">
                   {
                       getStatus() === 'DRAFT' || getStatus()==='ADMINTOUCH' ?
                           <div style={{
                               position: 'absolute',
                               right: 12,
                               top: 20
                           }}>
                           <ReadOnly>
                                <p onClick={() => {
                                   setAddCondition(true);
                               }} style={{
                                   color: "var(--primary-color)",
                                   cursor: 'pointer',
                                   fontWeight: 600,
                                   fontSize: 14
                               }}>
                                    + Add Condition
                               </p>
                           </ReadOnly>
                           </div> : null
                   }
               </Header>
           </div>
            <div className="input-container">
                {
                    props.conditions.length > 0 ?
                        props.conditions.map(showCondition) :
                        getStatus() !== 'DRAFT' ?
                            <p>No Condition Found</p> : null
                }
            </div>
            {
                addCondition ?
                    <HalfModal
                        title="Select condition"
                        container={{
                            left:'30%'
                        }}
                        desc="Select condition to add in po"
                        close={() => {
                            setAddCondition(false);
                            init();
                        }}
                    >

                        <ReadOnly>
                            <p onClick={()=>{setModal(true)}} 
                            style={{
                                    color: "var(--primary-color)",
                                    cursor: 'pointer',
                                    fontWeight: 600,
                                    fontSize: 14,
                                    padding:8,
                                    marginBottom:12
                                }}>
                                + Add Condition
                                </p>
                        </ReadOnly>
                        {
                            conditions.map(mapCondition)
                        }
                    </HalfModal>
                    : null
            }
            {
                edit ?
                    <Modal
                        show={edit}
                        title="Edit Condition"
                        des="Please fill up the details and add condition"
                        close={() => {
                            setEdit(false);
                        }}
                        hideButton={true}
                        onSave={() => {
                        }}>

                        <p style={{
                            fontSize: 14,
                            fontWeight: 600,
                            marginBottom: 5
                        }}>Content :</p>
                        <div style={{
                            minHeight: 250,
                            border: '1px solid var(--border-color)',
                            padding: 10
                        }}>
                            <TextEditorPrefill defaultValue={content} ref={editorRef} />
                        </div>
                        <div className="btn btn-portlet" style={{
                            marginTop: 20,
                            minWidth: '100%'
                        }} onClick={edits}>
                            Edit
                        </div>
                    </Modal>
                    : null
            }
            {
                modal ?
                    <Modal
                        show={modal}
                        title="Add Condition"
                        des="Please fill up the details and add condition"
                        close={() => {
                           setModal(false)
                        }}
                        hideButton={true}
                        onSave={() => { }}
                    >
                        <FunctionalInput title="Title" type="text" ref={TITLE} />
                        <p style={{
                            'marginTop':'5px','font-size': '10px','color': 'var(--light-color)','font-weight': 400,'letter-spacing': '1px','margin-bottom': '10px','text-transform': 'uppercase'
                        }}>Content :</p>
                            <TextEditorPrefill ref={editorRef} />
                        <div className="btn btn-portlet" style={{
                            marginTop: 20,
                            minWidth: '100%'
                        }} onClick={()=>{
                            addCustomCondition()
                        }}>
                            Add
                        </div>
                    </Modal>
                    : null
            }



        </div>
    )

}

export default Index;